body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fixed_video {
  max-width: 100%;
  width: 230px;
  position: fixed;
  border: 0;
  cursor: move;
  margin: 10px;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.video_wrapper {
  position: relative;
  padding-top: 140.25%;
}

.video_wrapper iframe,
.video_wrapper object,
.video_wrapper embed,
.video_wrapper video {
  border: 0;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  object-fit: cover;
  left: 0;
}

.click_overlay {
  position: fixed;
  cursor: pointer;
  display: block;
  left: 5px;
  bottom: 17.5rem;
  z-index: 9999;
  background-color: transparent;
  margin: 0;
  border: 0;
  color: transparent;
}

.click_overlay svg {
  width: 30px;
  height: 30px;
  background: rgb(255 255 255 / 55%);
  border-radius: 30%;
  padding: 4px;
  object-fit: scale-down;
  filter: invert(1);
}

.click_overlay2 {
  position: fixed;
  cursor: pointer;
  display: block;
  right: 5px;
  bottom: 17.5rem;
  /* bottom: 0.3rem; */
  z-index: 9999;
  background-color: transparent;
  margin: 0;
  border: 0;
  color: transparent;
}

.click_overlay2 svg {
  width: 30px;
  height: 30px;
  background: rgb(255 255 255 / 55%);
  border-radius: 30%;
  padding: 4px;
  object-fit: scale-down;
  color: #000000;
  filter: invert(1);
}

.click_overlay_vol {
  position: fixed;
  cursor: pointer;
  display: block;
  right: 5px;
  bottom: 0.3rem;
  z-index: 9999;
  background-color: transparent;
  margin: 0;
  border: 0;
  color: transparent;
}

.click_overlay_vol svg {
  width: 30px;
  height: 30px;
  background: rgb(255 255 255 / 55%);
  border-radius: 30%;
  padding: 4px;
  object-fit: scale-down;
  filter: invert(1);
  color: #000000;
}

.click_overlay_play,
.click_overlay_pause {
  position: fixed;
  cursor: pointer;
  display: block;
  left: 0;
  bottom: 0.3rem;
  z-index: 9999;
  background-color: transparent;
  margin: 0;
  border: 0;
  color: transparent;
}

.click_overlay_play svg,
.click_overlay_pause svg {
  width: 30px;
  height: 30px;
  background: rgb(255 255 255 / 55%);
  border-radius: 30%;
  padding: 4px;
  object-fit: scale-down;
  filter: invert(1);
  color: #000000;
}

.minimize_icon {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 40px;
  z-index: 9999;
  cursor: pointer;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #cccccc;
}

.minimize_icon video {
  object-fit: cover;
  border-radius: 50%;
  outline: 0px;
  /* outline: 2px solid #43b028; */
}

.minimize_icon span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 30px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  left: 4px;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

.coverflow_main .content video {
  object-fit: cover;
  -webkit-box-reflect: unset;
}

figure {
  -webkit-box-reflect: unset !important;
}

.video-size {
  height: 400px !important;
  border-radius: 10;
}

.coverflow-navigation span.left-nav {
  width: 40px;
  height: 40px;
  display: block;
  background: rgb(255 255 255 / 70%);
  border-radius: 50px;
  padding: 4px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 10px;
}

.coverflow-navigation span.right-nav {
  width: 40px;
  height: 40px;
  display: block;
  background: rgb(255 255 255 / 70%);
  border-radius: 50px;
  padding: 4px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 10px;
}

@media (max-width: 1024px) {
  .content {
    height: 100%;
  }
  .content figure div div {
    height: 270px !important;
  }
}

.slider-video-size {
  height: 400px !important;
  border-radius: 10;
  cursor: pointer;
}

.slider-video-size video{
  object-fit: cover;
  border-radius: 10px;
}


.video_slider .prevArrow {
  position: absolute;
  left: 5px;
  top: 30%;
  z-index: 12;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.video_slider .nextArrow {
  position: absolute;
  right: 5px;
  top: 30%;
  z-index: 12;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.video_slider {
  position: relative;
  margin: 0 3rem;
}

.video_slider .slick-slide {
  padding: 0 10px;
  box-sizing: border-box;
}